.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* these don't do anything on iOS */
/* video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.4)) !important;
  background-image: linear-gradient(transparent, transparent) !important;
} */

/* video::-webkit-media-controls-panel {
  background-color: transparent;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
